/* .react-calender,.react-calendar__navigation,.react-calendar__viewContainer {
    width: 600px !important;
} */
.react-calendar {
  width: 100% !important;
  height: 690px !important;
}
.react-calendar__tile {
  border: 1px solid grey !important;
  height: 100px !important;
}
.calendar-tile-content {
  /* border : 1px solid grey !important; */
  padding-top: 5px;
  padding-bottom: 5px;
}
