@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
}

.md-mobile-picker-header {
  font-size: 14px;
}

input.md-mobile-picker-input {
  color: initial;
  width: 100%;
  padding: 10px;
  margin: 6px 0 12px 0;
  border: 1px solid #ccc;
  background-color: #fffbef;
  border-radius: 0;
  font-family: arial, verdana, sans-serif;
  font-size: 14px;
  box-sizing: border-box;
  -webkit-appearance: none;
}

.md-mobile-picker-button.mbsc-button {
  font-size: 13px;
  padding: 0 15px;
  line-height: 36px;
  float: right;
  margin: 6px 0;
  width: 100%;
}

.mbsc-col-no-padding {
  padding-left: 0;
}

.md-mobile-picker-box-label.mbsc-textfield-wrapper-box,
.md-mobile-picker-box-label .mbsc-textfield-wrapper-box,
.md-mobile-picker-inline {
  margin: 6px 0 12px 0;
}

.accordion {
  width: 50%;
  margin: auto;
  background: grey;
  padding: 5px;
}

.accordion__item {
  background: white;
  margin-bottom: 5px;
}

.accordion__item:last-child {
  margin-bottom: 0;
}

.accordion__header {
  padding: 10px;
  background: rgb(169, 169, 169);
  line-height: 20px;
}

.accordion__panel {
  padding: 10px;
  background: rgb(0, 0, 0);
}

.dropdown:hover .dropdown-content {
  display: block;
}

.mbsc-scroller {
  background-color: black;
}
.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller,
.mbsc-ios.mbsc-scroller-pointer.mbsc-scroller-inline,
.mbsc-ios.mbsc-scroller-wheel-cont-inline.mbsc-scroller-wheel-cont-3d {
  background-color: #fffbef !important;
}

/* if code */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* spinner */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 8px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #17a589 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.custom-field {
  position: relative;
  font-size: 14px;
  border-top: 4px solid transparent;
  margin-bottom: 0px;
  display: inline-block;
  --field-padding: 8px;
}

.custom-field .input {
  border: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #f2f2f2;
  padding: var(--field-padding);
  border-radius: 3px;
  width: 100%;
  outline: none;
  font-size: 14px;
}

.custom-field .placeholder {
  position: absolute;
  left: var(--field-padding);
  width: calc(100% - (var(--field-padding) * 2));
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  top: 22px;
  line-height: 100%;
  transform: translateY(-50%);
  color: #aaa;
  transition: top 0.3s ease, color 0.3s ease, font-size 0.3s ease;
}

.custom-field .input.dirty + .placeholder,
.custom-field .input:focus + .placeholder,
.custom-field .input:not(:placeholder-shown) + .placeholder {
  top: -10px;
  font-size: 10px;
  color: #82e0aa;
}

.custom-field .error-message {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 8px;
  font-size: 12px;
  background: #d30909;
  color: #fff;
  height: 24px;
}

.custom-field .error-message:empty {
  opacity: 0;
}

.custom-field.one .input {
  background: none;
  border: 2px solid #ddd;
  transition: border-color 0.3s ease;
}

.custom-field.one .input + .placeholder {
  left: 8px;
  padding: 0 5px;
}

.custom-field.one .input.dirty,
.custom-field.one .input:not(:placeholder-shown),
.custom-field.one .input:focus {
  /* border-color: #82e0aa; */
  /* border-color: #4E90A8; */
  border-color: rgba((42, 34, 14, 0.45));
  transition-delay: 0.1s;
}

.custom-field.one .input.dirty + .placeholder,
.custom-field.one .input:not(:placeholder-shown) + .placeholder,
.custom-field.one .input:focus + .placeholder {
  top: 0;
  font-size: 10px;
  color: #0e6655;
  background: #fff;
  width: auto;
}

input#session-time {
  display: inline-block;
  position: relative;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.collapse {
  transition-property: height;
  transition-duration: 200ms;
  overflow: hidden;
}
