/* ::-webkit-scrollbar{
    display: visible !important;
}

.visible-scrollbar {
    scrollbar-width: auto;
  }

.practice-bg{
    background-color: blue;
} */


/* Scrollbar Track */
.custom-container::-webkit-scrollbar {
    width: 11px;
  }
  
  /* Scrollbar Track Background */
  .custom-container::-webkit-scrollbar-track {
    background: #48c9af16;
  }
  
  /* Scrollbar Thumb */
  .custom-container::-webkit-scrollbar-thumb {
    background-color: rgb(177, 52, 52);
    border-radius: 6px;
    border: 1px solid #4E90A8 !important;
    transition: #4E90A8 0.2s ease-in-out !important; 
  }
  
  /* Webkit Browsers */
  .custom-container::-webkit-scrollbar,
  .custom-container::-webkit-scrollbar-thumb {
    height: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    color: #4E90A8;
  }
  
  /* Webkit Thumb Shadow */
  .custom-container::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0 10px;
  }

