.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.spanRow:hover .spanText {
  opacity: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-shadow {
  --tw-shadow: 0 0px 15px -1px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.custom-border {
  border: 1px solid #868686;
}

.custom-border:focus {
  border: none;
}

/* .ReactModal__Overlay {
  background-color: rgba(219, 214, 203, 0.7) !important;

} */
.ReactModal__Overlay {
  background-color: rgba(134, 134, 134, 0.2) !important;
  z-index: 100;
  /* background-color: n; */
}

.mbsc-ios.mbsc-range-day:after {
  background-color: #4e90a8 !important;
  /* top: 1.1rem !important; */
}

.mbsc-ios.mbsc-range-control-wrapper .mbsc-segmented {
  display: none !important;
}

.mbsc-ios.mbsc-datepicker-inline {
  border-color: transparent !important;
}

.outline-custom {
  outline-color: rgb(41, 33, 13, 0.45);
}

.border-custom {
  border-color: rgb(41, 33, 13, 0.45);
}

/* all placeholder same color */

#input_focus::placeholder {
  color: #fffbef;
  opacity: 1;
  /* Firefox */
}

#modalPhone::placeholder {
  color: #fffbef;
  opacity: 1;
  /* Firefox */
}

#phone_number_placeholder::placeholder {
  color: #868686;
  opacity: 1;
  /* Firefox */
}

/* warning message color */
.__react_component_tooltip.type-warning {
  color: #000000;
  background: #fee29a !important;
  border: 1px solid transparent;
}

.__react_component_tooltip.type-warning.place-right::after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  left: -6px;
  top: 50%;
  margin-top: -4px;
  border-right-color: #fee29a !important;
  border-right-style: solid;
  border-right-width: 6px;
}

.ReactModal__Content {
  border: none !important;
  --tw-shadow: 0 0px 15px -1px rgb(0 0 0 / 0.25);
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.__react_component_tooltip.type-success {
  color: #fffbef;
  background: #4e90a8 !important;
  border: 1px solid transparent;
  border-right-color: #4e90a8 !important;
}

.__react_component_tooltip.type-success.place-right::after {
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  left: -6px;
  top: 50%;
  margin-top: -4px;
  border-right-color: #4e90a8 !important;
  border-right-style: solid;
  border-right-width: 6px;
}

/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: red !important;
  color: -internal-light-dark(black, white) !important;
} */

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input::placeholder {
  color: #9e9989;
  font-weight: 400;
}
/* test */

INPUT[type="checkbox"]:focus {
  outline: 2px solid rgba(0, 0, 0, 0.2);
}

INPUT[type="checkbox"] {
  background-color: #fffbef;
  outline: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  cursor: pointer;
  position: relative;
  top: 2px;
}

/* INPUT[type=checkbox]:checked
  {
    background-color: #4E90A8;
    background: #4E90A8 url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==") 3px 3px no-repeat;
  } */

INPUT[type="checkbox"]:checked {
  background-color: #376576;
  background: #376576
    url("data:image/gif;base64,R0lGODlhCwAKAIABAP////3cnSH5BAEKAAEALAAAAAALAAoAAAIUjH+AC73WHIsw0UCjglraO20PNhYAOw==")
    4px 4px no-repeat;
  background-size: 8px 8px;
  outline: 2px solid #376576;
}

input[type="text"],
input[type="tel"],
select,
input[type="number"],
input[type="password"] {
  outline: none !important;
}

input[type="number"] {
  overflow-y: hidden;
}

/* Prevent the default behavior of up and down arrow keys */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.errorText {
  color: #fe9a9a;
  text-align: left;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: rgb(224, 224, 224) transparent !important;
}
.container:-webkit-scrollbar {
  width: 11px;
}
.container:-webkit-scrollbar-track {
  background: transparent;
}
.container:-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 6px;
  border: 3px solid transparent;
}

/* webkit browsers */
.container::-webkit-scrollbar,
.container::-webkit-scrollbar-thumb {
  height: 26px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 10px solid transparent;
}

.container::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

.item-selected {
  background-color: rgb(78 144 168 / 1) !important;
  color: rgb(255 251 239 / 1) !important;
}

.sliderCustom {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 25px;
}

.sliderCustom:hover .sliderCustom::-webkit-slider-thumb {
  opacity: 1;
}

.sliderCustom::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(78 144 168 / 1);
  cursor: pointer;
  border-radius: 100%;
}
.sliderCustom2::-moz-range-thumb {
  z-index: 100;
  border: 0.5px solid #070707;
}
.sliderCustom2::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: rgb(4, 17, 100);
  cursor: pointer;
  border-radius: 100%;
  z-index: 100;
  border: 1px solid #070707;
}

.sliderCustom::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: rgb(78 144 168 / 1);
  cursor: pointer;
}
hr {
  color: #b8b8b8;
}
.ql-container.ql-snow {
  border: 1px solid #e2e2e2 !important;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
  min-height: 200px !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #e2e2e2;
  box-sizing: border-box;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  padding: 8px;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}
@media print {
  @page {
    margin: 0;
  }
  body {
    margin: 1.6cm;
  }
}
/* .custom-shadow */
