#mouse-info {
    box-sizing: border-box;
    margin: 0;
    padding: 8px;
    position: absolute;
    bottom: 24px;
    /* left: 8px; */
    z-index: 200;
    align-content: center;
    place-content: center;
    justify-self: center;
    /* background: gray; */
}