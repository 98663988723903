/* input */
input.md-mobile-picker-input {
    width: 100%;
    background-color: #FFFBEF;
    font-family: arial, verdana, sans-serif;
    font-size: 16px;
    height: 30px;
    border: none;
    padding-top: 19px;
    color: #2A220E;
}
/* input border focus */
input.md-mobile-picker-input:focus {
    outline: none;
}

/* .mbsc-calendar-table, .mbsc-calendar-week-days {
    display: table;
    table-layout: fixed;
    width: 100%;
    background-color: #FFFBEF;
} */
/* bg color */
.mbsc-ios.mbsc-datepicker .mbsc-calendar, .mbsc-ios.mbsc-datepicker .mbsc-calendar-cell, .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
    background: #FFFBEF;
}
/* calendar wrapper */
/* .mbsc-calendar-wrapper {
    position: relative;
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
    background-color: #FFFBEF;
    
} */
/* calendar bg */
/* .mbsc-ios.mbsc-datepicker .mbsc-calendar-slide {
    background: #FFFBEF;
} */

/* selected color */
.mbsc-ios.mbsc-selected .mbsc-calendar-cell-text {
    border-color: #376576;
    background: #376576;
    color: #fff;
}

/* today bg */
.mbsc-ios.mbsc-calendar-today, .mbsc-ios.mbsc-calendar-week-nr {
    color: #376576;
}

/* header component color */
.mbsc-ios.mbsc-calendar-button.mbsc-button {
    color: #376576;
}