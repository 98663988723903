::-webkit-scrollbar {
  background: #fffbef00;
  border-radius: 5px;
  width: 0px;
}

#scroll-x{
  overflow: hidden;
  overflow-y: overlay;
  /* overflow-y: hidden; */
  /* webkit- */
  /* scrollbar-gutter: 10; */
  /* scrollbar-width: thin !important; */
}
::-webkit-scrollbar-thumb:hover {
  background: #4e90a8;
}

::-webkit-scrollbar-thumb {
  background: #376576;
  border-radius: 10px;
}

