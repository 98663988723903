.Toastify__toast--error {
  border: 1px solid #eb5757;
  border-radius: 50px !important;
  background: #fae1e2 !important;
}

.Toastify__toast--error::after {
  /* content : url('../assets/images/svg/closeToast.svg');  */
  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--error::before {
  /* content: url("../assets/images/svg/errorToast.svg"); */
  position: sticky;
  z-index: 100000;
  left: 8px;
  top: 3px;
  /* top: 6px; */
}

.Toastify__toast--success {
  border: 1px solid #3a9ea5 !important;
  border-radius: 50px !important;
  background: #f0f9fa !important;
}

.Toastify__toast--success::before {
  content: url("./success.svg");

  position: sticky;
  z-index: 100000;
  left: 8px;
  top: 3px;

  /* display: flex; */
  /* align-items: center; */
  /* content center horizontally */
  justify-content: center;
}

.Toastify__toast--success::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  /* content: url('./success.svg'); */

  position: absolute;
  color: #333333;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast--warning {
  border: 1px solid #e78326 !important;
  border-radius: 50px !important;
  background: #fadfc5 !important;
}

.Toastify__toast--warning::before {
  /* content: url("../assets/images/svg/warnToast.svg"); */
  position: sticky;
  z-index: 100000;
  left: 8px;
  top: 3px;
  /* top: 6px; */
}

.Toastify__toast--warning::after {
  /* content : url('../assets/images/svg/closeToast.svg'); */
  position: absolute;
  color: #e78326;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}

.Toastify__toast-body {
  color: #444c63;
  font-size: 18px;
  padding-left: 2px;
  line-height: 20px;
  padding: 0px;
  /* padding-top: 25px; */
  width: 100%;
  font-weight: 400;
  margin-left: 2px !important;
}

.Toastify__toast>button>svg {
  display: none;
}

.Toastify__progress-bar {
  background-color: #e78326;
  color: #eb5757;
  background: none;
  height: 3px;
  width: 0;
  transition: width 0.1s ease-out;
}

/* .Toastify__toast--success > .Toastify__toast-body > .Toastify__toast-icon {
    content: url('./success.svg');
  } */

.Toastify__toast-icon>svg {
  /* hide svg */
  display: none;
}

.Toastify__toast--success {
  border: 0px solid #3a9ea5 !important;
  background-color: #fffbef !important;
}

/* .Toastify__progress-bar{
    height: none;
  } */