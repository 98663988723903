/* .selectImage_wrapper:hover {
    background: rgba(20, 20, 20, 0.897);
    background-blend-mode: overlay;
    opacity: 1;
    border-radius: 8px;
  }
  .selectImage:hover {
    opacity: 0.5;
  } */

  .selectImage_wrapper:hover {
    border: 1px solid #376576;
     box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;;
    border-radius: 8px;
  }
  .selectImage_wrapperActive {
    background: rgba(20, 20, 20, 0.897);
    background-blend-mode: overlay;
    opacity: 1;
    border-radius: 8px;
  }
  .selectImageActive {
    opacity: 0.5;
  }
  .selectImageActive2 {
    opacity: 1;
  }
