.Modal {
  position: absolute;
  top: 10px;
  bottom: 40px;
  /* background-color: green; */
  /* background: #fff; */
  width: 40%;
  overflow-y: auto;
}
.ModalSmall {
  position: absolute;
  top: 10px;
  bottom: 40px;
  /* background-color: green; */
  /* background: #fff; */
  width: 50%;
  overflow-y: auto;
}
.breadcrumb {
  display: none;
}
.miniNav {
  display: none;
}
@media only screen and (max-width: 640px) {
  .breadcrumb {
    display: block;
  }
  .ModalSmall {
    position: absolute;
    top: 10px;
    bottom: 40px;
    /* background-color: green; */
    /* background: #fff; */
    width: 80%;
    overflow-y: auto;
  }
}
