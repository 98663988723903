/* .flex_column {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
  } */
.ReactModal__Overlay {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.ReactModal__Content {
  background-color: #FFFBEF !important;
  border-radius: 32px !important;
  /* inset: 40px; */
  min-width: 500px;
  max-width: 85% !important;
  width: fit-content;
  max-height: 90% !important;
  height: max-content !important;
  overflow: scroll;
  overflow-x: hidden !important;
  overflow-y: overlay !important;
  left: 50% !important;
  top: 50% !important;
  transform: translateX(-50%) translateY(-50%) !important;
  position: relative;
}

.overlay_background {
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 50;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.15);
}

.overlay_shown {
  opacity: 1;
}

.overlay_hidden {
  display: none;
  opacity: 0;
}

.overlay_card {
  max-width: 90%;
  /* height: 95%; */
  border-radius: 10px;
  z-index: 51;
  background-color: #FFFBEF;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  width: 1280px;
}

.login_button {
  width: 200px;
  height: 60px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 30px;
  font-size: 25px;
  margin: 50px;
  transition: all .3s ease-out;
}

.login_button:hover,
.login_button:active {
  box-shadow: inset 0 0 40px rgba(256, 256, 256, 0.3);
}

.login_button {
  background-color: rgb(95, 215, 95);
}