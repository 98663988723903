h3 {
  font-size: 27px;
}
h4 {
  font-size: 22px;
}
h5 {
  font-size: 18px;
}

.ql-editor h3 {
  font-size: 27px !important;
}

.ql-editor h4 {
  font-size: 22px !important;
}

.ql-editor h5 {
  font-size: 18px !important;
}
