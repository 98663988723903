.steps_container {
  display: flex;
  width: 100%;
  padding-top: 20px;
}

.stepes {
  text-align: center;
  flex: 1;
  cursor: default;
  position: relative;
}

.stepes .line {
  height: 0px;
  border-width: 1px;
  border-style: solid;
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% + 40px);
  width: calc(100% - 85px);
  border-color: #879fa8;
}

.stepes .circle {
  /* padding: 16px 20px; */
  width: 25px;
  height: 25px;

  display: inline-block;
  border-radius: 50%;
  margin-bottom: 6px;
}

.step_active .circle {
  background: #4094b2;
  color: #fff;
}

.step_done .circle {
  background: #4094b2;
  color: #fff;
}

.step_inactive .circle {
  background: #879fa8;
  color: #fff;
}

.step_active .label {
  color: #4094b2;
  font-size: 12px;
}

.step_done .label {
  color: #4094b2;
  font-size: 12px;
}

.step_inactive .label {
  color: #879fa8;
  font-size: 12px;
}

.step_done .line {
  border-color: #4094b2;
}

.step_inactive .line,
.stepactive .line {
  border-color: #879fa8;
}

@media screen and (max-width: 767px) {
  .stepes .line {
    height: 0px;
    border-width: 1px;
    border-style: solid;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% + 30px);
    width: calc(60% - 25px);
    border-color: #879fa8;
  }
  .step_done .line {
    border-color: #4094b2;
  }
  .step_inactive .line,
  .stepactive .line {
    border-color: #879fa8;
  }
}
