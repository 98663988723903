.md-mobile-picker-header {
    font-size: 14px;
}

input.md-mobile-picker-input {
    color: initial;
    width: 100%;
    padding: 10px;
    margin: 6px 0 12px 0;
    border: 1px solid #ccc;
    background-color: #FFFBEF;
    border-radius: 0;
    font-family: arial, verdana, sans-serif;
    font-size: 14px;
    box-sizing: border-box;
    -webkit-appearance: none;
}

.md-mobile-picker-button.mbsc-button {
    font-size: 13px;
    padding: 0 15px;
    line-height: 36px;
    float: right;
    margin: 6px 0;
    width: 100%;
}

.mbsc-col-no-padding {
    padding-left: 0;
}

.md-mobile-picker-box-label.mbsc-textfield-wrapper-box,
.md-mobile-picker-box-label .mbsc-textfield-wrapper-box,
.md-mobile-picker-inline {
    margin: 6px 0 12px 0;
}


/* .mbsc-calendar-table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}
 .mbsc-flex-1-1   */
.mbsc-calendar {
    background-color: #000000;
}

.specialBody {
    border-color: rgba(42, 34, 14, 0.45) !important;
}

.primaryTransparentColor {
    background-color:  #37657674 !important;
}
.imageOption{
    opacity: 0 !important;
    transition: all 0.4s;
}
.imageHolder2{
    transition: all 0.4s;
}
.imageHolder2:hover{
    background-color: #376576cc;
    color: #FFFBEF !important;
}
.imageHolder:hover .imageOption{
    opacity: 1 !important;
}

.coverFittedImage {
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}

.customBorder{
    box-shadow: 
    1px 0 0 0 #888, 
    0 1px 0 0 #888, 
    1px 1px 0 0 #888,
    1px 0 0 0 #888 inset, 
    0 1px 0 0 #888 inset;
}

.validationError{
    /* outline-color: #FE9A9A !important; */
    border-color: #FE9A9A !important;
    color: #FE9A9A !important;
    font-size: small;
}


INPUT[type=radio]
{
    background-color: #FFFBEF;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    border: 1px solid #868686;
    border-radius: 16px;
}

INPUT[type=radio]:checked
{
    background-color: #FFFBEF;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: relative;
    border: 5px solid #376576;
    border-radius: 16px;
}