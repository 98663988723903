.planScrool {
  /* background: #1e90a5; */
  width: 100%;
}

.slideScrool {
  overflow-x: scroll;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.slideScrool::-webkit-scrollbar {
  display: none;
}

.planScrool {
  position: relative;
  display: flex;
  align-items: center;
}

/* modal */
.modal_backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  z-index: -1;
}

.modal_content {
  width: 100%;
  /* min-height: 100%; */
  border: 1px solid #cac5b7;
  background-color: #fefcf5;
  padding: 0px;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -200%);
  transition: all 0.2s ease-in-out;
  perspective: 1000;
  outline: 1px solid transparent;
  opacity: 0;
}

.modal_backdrop.show {
  opacity: 1;
  transition: all 0.2s ease-in-out;
  z-index: +100;
}

.modal_backdrop.show .modal_content {
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
  transition-delay: 0.2s;
  z-index: 9999;
  opacity: 1;
}
@media screen and ((min-width: 300px) and (max-width: 767px)) {
  .modal_content {
    width: 343px;
    min-height: 388px;
    border-radius: 12px;
  }
}

@media screen and ((min-width: 768px) and (max-width: 1023px)) {
  .modal_content {
    width: 543px;
    min-height: 488px;
    border-radius: 12px;
  }
}
