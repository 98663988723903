.timeline {
  width: 100%;
  height: 100px;
  /* max-width: 800px; */
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.timeline .events {
  position: relative;
  background-color: #376576;
  height: 2px;
  width: 100%;
  border-radius: 4px;
  margin: 5em 0;
}

.timeline .events ol {
  margin: 0;
  padding: 0;
  text-align: center;
}

.timeline .events ul {
  list-style: none;
}

.timeline .events ul li {
  display: inline-block;
  width: 18.65%;
  margin: 0;
  padding: 0;
}
.timeline .events ul li .selected {
    font-size: 40px;
    font-weight: 400;
    color: #2A220E;
    text-decoration: none;
    position: relative;
    top: -72px;
    text-align: center;
  }
.timeline .events ul li a {
  font-size: 40px;
  font-weight: 400;
  color: #868686;
  text-decoration: none;
  position: relative;
  top: -72px;
  cursor: pointer;
}


.timeline .events ul li a:after {
  content: "";
  position: absolute;
  bottom: -18px;
  left: 50%;
  right: auto;
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #4E90A8;
  border-color: #4E90A8;
  border: 3px solid #4E90A8;
  transition: 0.3s ease;
  transform: translateX(-50%);
}

.timeline .events ul li a:hover::after {
  background-color: #4E90A8;
  border-color: #4E90A8;
}

.timeline .events ul li a.selected:after {
  height: 7px;
  width: 7px;
  background-color: #4E90A8;
  border-color: #4E90A8;
}


